import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RecentPosts from "../components/recent-posts"
import Logo from "../components/logo"

import Connect from "../components/connect"

const IndexPage = ({ location }) => {
  const education = [
    {
      degrees:
        "BA in Japanese and Comparative Studies from The Ohio State University",
      year: "2009",
    },
    {
      degrees: "MA in Digital Game Theory and Design from Brunel University",
      year: "2011",
    },
    {
      degrees: "Web Accessibility Specialist (WAS) Certification",
      year: "2020",
    },
  ]
  // const languages = acf.languages
  const languages = [
    { certification: "", title: "HTML" },
    { certification: "", title: "CSS/SCSS" },
    { certification: "", title: "Javascript (ES6" },
    { certification: "", title: "React" },
    { certification: "", title: "Wordpress" },
    { certification: "", title: "PHP/Twig" },
  ]
  // const interests = acf.five_things

  const renderInterests = interestData => {
    // interest_text: "SAWAYAMA", icon: "🎧", interests:

    const interestsMap = interestData.map((interest, i) => {
      return (
        <li
          className="interests__item"
          key={`${i}-${interest.field_5c67823bd9351}`}
        >
          <a className="interests__link" href={interest.field_5c67851666dd3}>
            {interest.field_58a9d387730c5}
            {interest.field_5c67823bd9351}
          </a>
        </li>
      )
    })
    return (
      <div className="interests">
        <ul className="interests-list">{interestsMap}</ul>
      </div>
    )
  }

  const renderEducation = educationData => {
    const educationMap = educationData.map((school, i) => {
      return (
        <li className="skills__item" key={`${i}-${school.degrees}`}>
          {school.degrees} ({school.year})
        </li>
      )
    })
    return (
      <>
        <h2 className="skills__heading">Education &amp; Certifications</h2>
        <ul className="skills__list">{educationMap}</ul>
      </>
    )
  }

  const renderLanguages = languageData => {
    // title
    // certification

    const languageMap = languageData.map((language, i) => {
      return (
        <li className="skills__item" key={`${i}-${language.title}`}>
          {language.title}
        </li>
      )
    })
    return (
      <>
        <h2 className="skills__heading">What I do</h2>
        <ul className="skills__list skills__list--columns">{languageMap}</ul>
      </>
    )
  }

  return (
    <Layout location={location}>
      <SEO title="Frontend Developer" />
      <div className="container container--70-30">
        <div className="container__col">
          <div className="main-content__intro-container">
            <div className="main-content__logo">
              <Logo />
            </div>
            <h1 className="main-content__title">Hi, I'm Stacey.</h1>
          </div>
          <div className="main-content__content">
            I'm a Boston-based <strong>front-end developer</strong> and{" "}
            <strong>web accessibility specialist</strong>. Currently I'm working
            in product land; previously, I built websites at agencies and as a
            student at{" "}
            <a href="https://www.osu.edu/" rel="noreferrer">
              The Ohio State University
            </a>
            . These days, I mostly work in React, and I have several years
            experience building custom Wordpress themes and interfaces. The
            current iteration of this site is built using GatsbyJS. Most of my
            work is tied up in client projects, but I'm available to connect on{" "}
            <a href="https://github.com/heyitsstacey">Github</a> or
            <a href="https://www.linkedin.com/in/staceyschlanger">LinkedIn</a>.
          </div>
          <Connect showHeading={true} />
        </div>
        <div className="container__col">
          <RecentPosts />
        </div>
        {/* Five things? */}
        {/* {interests && renderInterests(interests)} */}
      </div>
      <div className="skills">
        <div className="container container--30-70 container--30-70-border">
          <div className="container__col">
            {languages && renderLanguages(languages)}
          </div>
          <div className="container__col">
            {education && renderEducation(education)}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
